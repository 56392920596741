import styles from './Content.module.css'
import FSI from '../../../../../Images/FSI.webp';
import ed1 from '../../../../../Images/ed1.webp';
import ed2 from '../../../../../Images/ed2.webp';
import ed3 from '../../../../../Images/ed3.webp';
import ed4 from '../../../../../Images/ed4.webp';
import { useLocation, useNavigate } from "react-router-dom";

function Content() {
    let navigate = useNavigate();
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <div className={styles.ProjectsWrapper}>
                    <div style={{ width: 1320, marginTop: 60, padding: "10px" }}>
                        <div className={styles.catWrapper}>
                        </div>
                        <div className={styles.firstText}>
                            Компания Научный центр "Полюс" разрабатывает <span style={{ color: "#4A6FF5" }}> новое программно-инженерное решение </span> для дошкольного образования с уклоном в логопедию.
                        </div>
                        <div className={styles.secondText} style={{ marginTop: 60 }}>
                            Изделие представлено: в виде "котика", а также  разработке находиться сайт "MobiTest".
                            Обладает интерактивными функциями для взаимодействия с детьми. Как показал опыт апробации, детям не только нравится сам робот, его вид и способ взаимодействия с детьми, но и формат игр, который представлен в MEOW.
                        </div>
        

                        <div className={styles.BlockTextWrapper}>
                            <div className={styles.svgWrapper}>
                                <svg width="30" height="263" viewBox="0 0 30 263" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 25L29.4338 6.31186e-07L0.56625 -6.31185e-07L12.5 25L17.5 25ZM12.5 260C12.5 261.381 13.6193 262.5 15 262.5C16.3807 262.5 17.5 261.381 17.5 260L12.5 260ZM12.5 22.5L12.5 260L17.5 260L17.5 22.5L12.5 22.5Z" fill="#8A9FEB" />
                                </svg>
                            </div>
                            <div className={styles.FSIwrapper}>
                                <div className={styles.textWrapper}>
                                    <div className={styles.HeaderText}>Поддержаны «Фондом содействия развитию малых форм предприятий в научно-технической сфере»</div>
                                    <div className={styles.MediumText} style={{ marginTop: 30 }}>Проект Платформа речевого развития <span style={{ color: "#4A6FF5" }}>«РобоЛого» поддержан</span> Федеральным государственным бюджетным учреждением «Фондом содействия развитию малых форм предприятий в научно-технической сфере»<span style={{ color: "#4A6FF5" }}>в рамках федерального проекта «Искусственный интеллект»</span> национальной программы «Цифровая экономика Российской Федерации»</div>
                                    <div className={styles.SmallText} style={{ marginTop: 20 }}>С историей нашего успеха вы можете ознакомиться на странице “О нас” или нажав на кнопку ниже</div>
                                </div>
                                <div className={styles.FSI} >
                                    <img src={FSI} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonWrapper} onClick={() => {
                            window.scrollTo({ top: 0 });
                            navigate('/about')
                        }}>
                            <div className={styles.buttonText}>
                                История успеха
                            </div>
                        </div>

                        <div className={styles.listWithIconsWrapper}>
                            <div>
                                <div className={styles.listHeader}>
                                Список учреждений, предоставляющих датасет:
                                </div>
                                <div className={styles.listWrapper}>
                                    <ol>
                                        <li>Муниципальное бюджетное общеобразовательное учреждение "Богашевская средняя общеобразовательная школа им. А.И.Федорова" Томского района (МБОУ "Богашевская СОШ им. А.И.Федорова" Томского района).</li>
                                        <li>Муниципальное бюджетное дошкольное образовательное учреждение "Детский сад п.Аэропорт" Томского района(МБДОУ "Детский сад п. Аэропорт" Томского района)</li>
                                        <li>Муниципальное бюджетное общеобразовательное учреждение "Лучановская средняя общеобразовательная школа им. В.В.Михетко" Томского района (МБОУ "Лучановская СОШ им. В.В. Михетко" Томского района)</li>
                                        <li>Муниципальное автономное дошкольное образовательное учреждение детский сад комбинированного вида № 1 г.Томска (МАДОУ №1 г.Томска)</li>
                                        <li>Муниципальное автономное дошкольное образовательное учреждение центр развития ребенка - детский сад № 96 г.Томска (МАДОУ № 96 г.Томска)</li>
                                    </ol>
                                </div>
                            </div>
                            <div className={styles.iconsWrapper}>
                                <div className={styles.iconsSubWrapper}>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <div className={styles.ed1style}><img src={ed1} /></div>
                                        <div className={styles.ed3style}><img src={ed3} /></div>
                                    </div>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <div className={styles.ed4style}><img src={ed4} /></div>
                                        <div className={styles.ed2style}><img src={ed2} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.thirdText} style={{ marginTop: 100 }}>
                            Возможности платформы:
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.CompanyDescriptionWrapper}>
                <div style={{ width: 1305, padding: "10px" }}>

                </div>
            </div>
        </div>
    );
}


export default Content;
